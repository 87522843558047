import { ReactNode } from 'react';
import { useNavigate } from 'react-router';
// hooks
import useLocales from 'src/hooks/useLocales';
//
import Page from './Page';

// ----------------------------------------------------------------------

type Props = {
  title: string;
  children: ReactNode;
  backRoute: string;
  noPadding?: boolean;
  nested?: boolean; // Nested page will not have min height since the outer page already handled this
};

// ----------------------------------------------------------------------

export default function ActionPage({ title, children, backRoute, noPadding, nested }: Props) {
  const navigate = useNavigate();

  // HOOK
  const { translate } = useLocales();

  return (
    <Page title={translate(title)} noPadding={noPadding} nested={nested}>
      <div className="gv-breadcrumbs gv-mb-sm">
        <a
          href="/#"
          onClick={(e) => {
            e.preventDefault();
            navigate(backRoute);
          }}
          style={{ cursor: 'pointer' }}
        >
          <gv-icon src="/src/icons/arrow_back.svg" />
          <span>{translate('wpone.general.action.back')}</span>
        </a>
      </div>

      {children}
    </Page>
  );
}

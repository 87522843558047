// ----------------------------------------------------------------------

export type DomainState = {
  editingDnsRecord: DnsRecord | null;
};

export enum TTL {
  auto = 'auto',
  oneHour = '1 hour',
  twoHours = '2 hours',
  fourHours = '4 hours',
  fiveHours = '5 hours',
  sixteenHours = '16 hours',
  oneDay = '1 day',
}

export type ImportRecordType = {
  fileContent: string;
  name: string;
  ttl: TTL;
  type: string;
  weight?: string;
  priority?: number;
  port?: string;
  content: string;
};

export type DnsRecord = {
  id: string;
  name: string;
  content: string;
  created_on: string;
  modified_on: string;
  locked?: boolean;
  meta?: {
    auto_added: boolean;
    managed_by_apps: boolean;
    managed_by_argo_tunnel: boolean;
    source: string;
  };
  priority?: number;
  proxiable?: boolean;
  proxied?: boolean;
  ttl: TTL;
  type: string;
  zone_id: string;
  zone_name: string;
};

export enum StateTypeEnum {
  success = 'success',
  error = 'error',
  warning = 'warning',
}

import { useEffect } from 'react';
import { Outlet, useLocation, useParams } from 'react-router';
// redux
import { useDispatch } from 'src/redux/store';
import { setTabConfig } from 'src/redux/features/layout';
// config
import { PAGE_DETAILS_TAB_CONFIG } from 'src/config';
// components
import { SidebarPage as MuiSidebarPage } from 'src/components/page';
import { SidebarPage } from 'src/components/gravity/page';

// ----------------------------------------------------------------------

export default function DnsDetailsLayout() {
  const { pathname } = useLocation();

  const { name } = useParams();

  const dispatch = useDispatch();

  // VAR
  const tabname = pathname.split('/')[2];

  //
  useEffect(() => {
    dispatch(setTabConfig(PAGE_DETAILS_TAB_CONFIG.dns));

    return () => {
      dispatch(setTabConfig(undefined));
    };
  }, [dispatch]);

  // Set only page that is being gravitified here
  return tabname === 'settings' ? (
    <SidebarPage title={`${name}`}>
      <Outlet />
    </SidebarPage>
  ) : (
    <MuiSidebarPage title={`${name}`} enableBackgroundColor>
      <Outlet />
    </MuiSidebarPage>
  );
}

import { ReactNode } from 'react';
import { Navigate, useParams } from 'react-router-dom';
// redux
import { useGetDomainQuery } from 'src/redux/api/domainApi';
// components
import LoadingScreen from 'src/components/LoadingScreen';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

// ----------------------------------------------------------------------

export default function DnsDetailsGuard({ children }: Props) {
  const { name } = useParams();

  // API
  const { data: domain, isFetching: isDomainFetching } = useGetDomainQuery(name);

  if (isDomainFetching) {
    return <LoadingScreen />;
  } else {
    if (!domain) {
      return <Navigate to="/404" replace />;
    }

    return <>{children}</>;
  }
}

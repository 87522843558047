import { ReactNode, useEffect } from 'react';
import { Navigate, useParams } from 'react-router';
// redux
import { useDispatch, useSelector } from 'src/redux/store';
import { useGetSiteQuery } from 'src/redux/api/siteApi';
import { useGetUpdatesQuery } from 'src/redux/api/updateApi';
import { setSidebarHighlightActivePage } from 'src/redux/features/layout';
// @types
import { InstallationProvider } from 'src/@types/site';
// utils
import { getUpdateDetails, getUpdateReport } from 'src/utils/getSiteUpdateInfo';
// mock
import { DUMMY_UPDATES_DATA } from 'src/mock/sites';
// components
import LoadingScreen from 'src/components/LoadingScreen';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

// ----------------------------------------------------------------------
// WPONE

export function WPONEUpdatesSubPageGuard({ children }: Props) {
  const { name, updateReportId, updateDetailsName } = useParams();

  const dispatch = useDispatch();

  // API
  const { data: siteUpdates, isFetching: isSiteUpdatesFetching } = useGetUpdatesQuery(name);

  // VAR
  const updateReport = getUpdateReport(updateReportId as string, siteUpdates?.data.updateList);
  // const updateReport = getUpdateReport(updateReportId as string, DUMMY_UPDATES_DATA.updateList); // TESTING CODE
  const updateDetails = getUpdateDetails(updateDetailsName as string, updateReport);

  //
  useEffect(() => {
    if (updateReportId || updateDetailsName) dispatch(setSidebarHighlightActivePage(false));

    return () => {
      dispatch(setSidebarHighlightActivePage(true));
    };
  }, [dispatch, updateDetailsName, updateReportId]);

  if (isSiteUpdatesFetching) {
    return <LoadingScreen />;
  }

  if (!siteUpdates || !updateReport || (updateDetailsName && !updateDetails)) {
    return <Navigate to="/404" />;
  }

  return <>{children}</>;
}

// ----------------------------------------------------------------------
// MWP

export function MWPUpdatesSubPageGuard({ children }: Props) {
  const { name, updateReportId, updateDetailsName } = useParams();

  const dispatch = useDispatch();

  // API
  const { data: siteUpdates, isFetching: isSiteUpdatesFetching } = useGetUpdatesQuery(name);

  // VAR
  // const updateReport = getUpdateReport(updateReportId as string, siteUpdates?.data.updateList);
  const updateReport = getUpdateReport(updateReportId as string, DUMMY_UPDATES_DATA.updateList); // TESTING CODE
  const updateDetails = getUpdateDetails(updateDetailsName as string, updateReport);

  //
  useEffect(() => {
    if (updateReportId || updateDetailsName) dispatch(setSidebarHighlightActivePage(false));

    return () => {
      dispatch(setSidebarHighlightActivePage(true));
    };
  }, [dispatch, updateDetailsName, updateReportId]);

  if (isSiteUpdatesFetching) {
    return <LoadingScreen />;
  }

  if (!siteUpdates || !updateReport || (updateDetailsName && !updateDetails)) {
    return <Navigate to="/404" />;
  }

  return <>{children}</>;
}

export function MWPUpdatesManualPageGuard({ children }: Props) {
  const { cluster, namespace, name } = useParams();

  // API
  const { data: site, isFetching: isSiteFetching } = useGetSiteQuery({
    namespace: namespace as string,
    cluster: cluster as string,
  });

  // STATE
  const updatingSite = useSelector((state) => state.site.updatingSites).find(
    (site) => site.siteName === name
  );

  if (isSiteFetching) {
    return <LoadingScreen />;
  }

  if (!site || site.provider === InstallationProvider.WP_ONE) {
    return <Navigate to="/404" />;
  }

  if (updatingSite) {
    return <Navigate to={`/sites/updates/${cluster}/${namespace}/${name}`} />;
  }

  return <>{children}</>;
}

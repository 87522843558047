import { RefObject, useEffect } from 'react';
import { useLocation, useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
// redux
import { useSelector } from 'src/redux/store';
// @types
import { UserRoleEnum } from 'src/@types/user';
import { SiteBrandEnum } from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { fCapitalizeFirstLetter } from 'src/utils/format';
// components
import Badge from 'src/components/gravity/Badge';

// ----------------------------------------------------------------------

type Props = {
  openTabDrawer: boolean;
  handleCloseTabDrawer: VoidFunction;
  tabDrawerRef: RefObject<HTMLDivElement>;
};

// ----------------------------------------------------------------------

export default function TabDrawer({ openTabDrawer, handleCloseTabDrawer, tabDrawerRef }: Props) {
  const { pathname } = useLocation();

  const { cluster, namespace, name } = useParams();

  // HOOK
  const { translate } = useLocales();

  // STATE
  // tabConfig will never be undefined since the tabDrawer icon will be hidden from the header when the tabConfig is undefined
  const { sidebarHighlightActivePage, tabConfig, siteBrand } = useSelector((state) => state.layout);

  const { user } = useSelector((state) => state.auth);

  // VAR
  const tabname = pathname.split('/')[2];

  //
  useEffect(
    () => () => {
      handleCloseTabDrawer();
    },
    [handleCloseTabDrawer]
  );

  return (
    <>
      {tabConfig ? (
        <div className="gv-activated">
          <div className="gv-sidedrawer gv-sidedrawer-open" style={{ zIndex: 1200 }}>
            {/* Here not only we need to make the body overflow to hidden but also we need to add the style overflow hidden to the drawer content as well, this is for preventing drawer content to be scrollable when broswer height is small */}
            <div
              className="gv-side-content"
              ref={tabDrawerRef}
              style={{
                overflow: openTabDrawer ? 'hidden' : 'auto',
              }}
            >
              <button className="gv-btn-close" onClick={handleCloseTabDrawer}>
                <gv-icon src="/src/icons/close.svg" />
              </button>

              {user?.role === UserRoleEnum.support && siteBrand && (
                <Badge
                  type="info"
                  text={
                    siteBrand === SiteBrandEnum.metanet
                      ? siteBrand.toUpperCase()
                      : fCapitalizeFirstLetter(siteBrand)
                  }
                />
              )}

              <div className="gv-side-menu">
                <div className="gv-side-label">{translate(tabConfig.title)}</div>
                <nav className="gv-side-nav">
                  <ul>
                    {tabConfig.navConfig.map((tab, index) => (
                      <RouterLink
                        key={index}
                        to={
                          tabConfig.primaryPathname === 'sites'
                            ? `/${tabConfig.primaryPathname}/${tab.tabName}/${cluster}/${namespace}/${name}`
                            : tabConfig.primaryPathname === 'dns'
                            ? `/${tabConfig.primaryPathname}/${tab.tabName}/${name}`
                            : `/${tabConfig.primaryPathname}/${tab.tabName}`
                        }
                        className={`gv-nav-item${
                          tab.tabName === tabname && sidebarHighlightActivePage ? ' gv-active' : ''
                        }`}
                        onClick={handleCloseTabDrawer}
                      >
                        <gv-icon src={`/src/icons/${tab.icon}.svg`} />
                        <span className="gv-text-truncate">{translate(tab.title)}</span>
                      </RouterLink>
                    ))}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

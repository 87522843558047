import { ReactNode } from 'react';
import { Navigate, useParams } from 'react-router-dom';
// utils
import { isMwpTestingSite } from 'src/utils/check';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

// ----------------------------------------------------------------------

export default function MWPTestingSiteGuard({ children }: Props) {
  const { name } = useParams();

  if (!isMwpTestingSite(name)) {
    return <Navigate to="/404" replace />;
  }

  return <>{children}</>;
}
